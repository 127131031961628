import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
  active?: boolean;
  payload?: any[];
  label?: string;
  aggregationType?: string;
  isHistoricalChart?: boolean;
}

export const ChartTooltip = ({ active, payload, label, aggregationType, isHistoricalChart }: CustomTooltipProps) => {
  if (!active || !payload?.length) {
    return null;
  }

  const formatLabel = () => {
    if (!isHistoricalChart && aggregationType === 'hourly') {
      // For hourly data in the main chart, show hour range (e.g., "05-06")
      return `Klo ${label ? `${label.split(':')[0]}-${String(Number(label.split(':')[0]) + 1).padStart(2, '0')}` : ''}`;
    }

    if (isHistoricalChart && aggregationType === 'hourly' && payload[0]?.payload?.hour) {
      // For historical hourly data, show both date and time
      const date = new Date(payload[0].payload.hour);
      return `${format(date, 'dd.MM.yyyy', { locale: fi })} klo ${format(date, 'HH:00', { locale: fi })}`;
    }

    // For historical chart or non-hourly data, show the original label
    return label;
  };

  return (
    <div className="bg-popover border border-border rounded-lg p-2 shadow-lg">
      <p className="text-sm font-medium">
        {formatLabel()}
      </p>
      <p className="text-sm">
        {`${payload[0].value.toFixed(2).replace('.', ',')} snt/kWh`}
      </p>
    </div>
  );
};