
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { DailyPrice, TimeRange } from "@/types/price";
import { memo, useState, useCallback, useEffect } from "react";
import PriceChart from "./PriceChart";
import { TimeRangeSelector } from "./historical-prices/TimeRangeSelector";
import { AggregationSelector } from "./historical-prices/AggregationSelector";
import { AveragePriceCard } from "./historical-prices/AveragePriceCard";

interface HistoricalPricesProps {
  prices: DailyPrice[];
  onTimeRangeChange: (timeRange: TimeRange, startDate?: Date, endDate?: Date) => void;
  isLoading?: boolean;
}

const HistoricalPrices = memo(({ prices, onTimeRangeChange, isLoading }: HistoricalPricesProps) => {
  const [timeRange, setTimeRange] = useState<TimeRange>("7d");
  const [aggregation, setAggregation] = useState<string>("hourly");
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const handleTimeRangeChange = useCallback((value: TimeRange) => {
    console.log('Time range change triggered:', value);
    setTimeRange(value);
    if (value === 'custom' && startDate && endDate) {
      console.log('Calling onTimeRangeChange with custom dates:', { startDate, endDate });
      onTimeRangeChange(value, startDate, endDate);
    } else {
      onTimeRangeChange(value);
    }
  }, [onTimeRangeChange, startDate, endDate]);

  const handleDateChange = useCallback((start?: Date, end?: Date) => {
    console.log('Date change triggered:', { start, end });
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      console.log('Calling onTimeRangeChange with new dates:', { start, end });
      onTimeRangeChange('custom', start, end);
    }
  }, [onTimeRangeChange]);

  // Only adjust aggregation for longer time periods
  useEffect(() => {
    if (timeRange === "5y") {
      setAggregation("yearly");
    } else if (timeRange === "1y") {
      setAggregation("monthly");
    } else if (timeRange === "30d") {
      setAggregation("daily");
    } else {
      setAggregation("hourly"); // Default to hourly for shorter periods
    }
  }, [timeRange]);

  const isHourlyDisabled = timeRange !== "7d" && timeRange !== "custom";
  const isDailyDisabled = timeRange === "1y" || timeRange === "5y";

  const validPrices = Array.isArray(prices) ? prices : [];

  return (
    <Card className="mx-[-1rem] sm:mx-0">
      <CardHeader>
        <CardTitle>Pörssisähkön keskihinnat</CardTitle>
        <div className="space-y-4">
          <TimeRangeSelector
            timeRange={timeRange}
            onTimeRangeChange={handleTimeRangeChange}
            startDate={startDate}
            endDate={endDate}
            onDateChange={handleDateChange}
          />

          <AggregationSelector
            aggregation={aggregation}
            onAggregationChange={setAggregation}
            isHourlyDisabled={isHourlyDisabled}
            isDailyDisabled={isDailyDisabled}
          />

          <AveragePriceCard prices={validPrices} />
        </div>
      </CardHeader>
      <CardContent>
        <PriceChart 
          data={validPrices}
          aggregationType={aggregation}
          isLoading={isLoading}
          isHistoricalChart={true}
        />
      </CardContent>
    </Card>
  );
});

HistoricalPrices.displayName = "HistoricalPrices";

export default HistoricalPrices;
