
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { PriceData } from "@/types/price";
import { format, addHours } from "date-fns";
import { fi } from "date-fns/locale";
import { useEffect, useState } from "react";

interface CurrentPriceProps {
  currentPrice?: PriceData;
  nextPrice?: PriceData;
}

const CurrentPrice = ({ currentPrice, nextPrice }: CurrentPriceProps) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      setCurrentTime(now);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  if (!currentPrice) {
    return (
      <Card className="shadow-lg border-0 card-gradient">
        <CardHeader className="border-b border-blue-100/50">
          <CardTitle className="text-blue-900">Sähkön hinta nyt</CardTitle>
        </CardHeader>
        <CardContent className="pt-6">
          <div className="text-muted-foreground">Ei hintatietoja saatavilla</div>
        </CardContent>
      </Card>
    );
  }

  const price = +(currentPrice.price).toFixed(2);
  const nextHourPrice = nextPrice ? +(nextPrice.price).toFixed(2) : null;
  const priceHour = new Date(currentPrice.hour);
  const nextHour = addHours(priceHour, 1);
  const timeRange = `${format(priceHour, 'HH')}-${format(nextHour, 'HH')}`;

  const getPriceColor = (price: number) => {
    if (price < 5) return 'text-price-low';
    if (price < 10) return 'text-price-medium';
    return 'text-price-high';
  };

  const formatPrice = (price: number) => {
    return price.toLocaleString('fi-FI', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  return (
    <Card className="shadow-lg border-0 card-gradient">
      <CardHeader className="border-b border-blue-100/50">
        <CardTitle className="text-blue-900">Sähkön hinta nyt</CardTitle>
      </CardHeader>
      <CardContent className="pt-6">
        <div className="text-4xl font-bold mb-4">
          <span className={getPriceColor(price)}>{formatPrice(price)}</span>
          <span className="text-2xl ml-2">snt/kWh</span>
        </div>
        <div className="space-y-2">
          <p className="text-slate-600">Aikaväli: {timeRange}</p>
          <p className="text-slate-600 font-mono">
            {format(currentTime, 'HH:mm:ss', { locale: fi })}
          </p>
          {nextHourPrice !== null && (
            <p className="text-slate-600 mt-4">
              Seuraavan tunnin hinta: <span className={getPriceColor(nextHourPrice)}>{formatPrice(nextHourPrice)}</span> snt/kWh
            </p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default CurrentPrice;
