import { usePriceData } from "@/hooks/usePriceData";
import CurrentPrice from "@/components/CurrentPrice";
import PriceChart from "@/components/PriceChart";
import PriceTable from "@/components/PriceTable";
import HistoricalPrices from "@/components/HistoricalPrices";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { calculateAverage } from "@/services/priceService";
import { useState, useMemo, useCallback, useEffect } from "react";
import { TimeRange } from "@/types/price";
import { startOfHour, addDays, isBefore } from "date-fns";
import Header from "@/components/layout/Header";
import Footer from "@/components/layout/Footer";
import Content from "@/components/layout/Content";

const Index = () => {
  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange>("7d");
  const [selectedDay, setSelectedDay] = useState<'today' | 'tomorrow'>('today');
  const [currentHour, setCurrentHour] = useState(() => new Date());
  const [customStartDate, setCustomStartDate] = useState<Date>();
  const [customEndDate, setCustomEndDate] = useState<Date>();
  
  const { latestPrices, dailyPrices, historicalPrices, isLoading } = usePriceData(
    selectedTimeRange, 
    selectedDay,
    customStartDate,
    customEndDate
  );

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      if (startOfHour(now).getTime() !== startOfHour(currentHour).getTime()) {
        setCurrentHour(now);
      }
    }, 60000);

    return () => clearInterval(timer);
  }, [currentHour]);

  const memoizedDailyPrices = useMemo(() => {
    if (!Array.isArray(dailyPrices)) return [];
    return dailyPrices;
  }, [dailyPrices]);

  const memoizedHistoricalPrices = useMemo(() => {
    if (!Array.isArray(historicalPrices)) return [];
    return historicalPrices;
  }, [historicalPrices]);

  const { currentPrice, nextPrice } = useMemo(() => {
    if (!latestPrices?.prices || latestPrices.prices.length === 0) {
      return { currentPrice: undefined, nextPrice: undefined };
    }

    const now = new Date();
    const currentHourStr = startOfHour(now).toISOString();
    
    console.log('Current Finnish time:', now.toLocaleString('fi-FI'));
    console.log('Current hour (ISO):', currentHourStr);
    console.log('Available prices:', latestPrices.prices.map(p => ({ 
      hour: p.hour, 
      localTime: new Date(p.hour).toLocaleString('fi-FI'),
      price: p.price 
    })));
    
    const currentPriceData = latestPrices.prices.find(price => {
      const priceHour = startOfHour(new Date(price.hour)).toISOString();
      console.log('Comparing:', { 
        priceHour, 
        currentHourStr, 
        priceLocal: new Date(price.hour).toLocaleString('fi-FI'),
        currentLocal: new Date(currentHourStr).toLocaleString('fi-FI'),
        match: priceHour === currentHourStr 
      });
      return priceHour === currentHourStr;
    });

    const nextHourTime = startOfHour(addDays(now, 1));
    const nextPriceData = latestPrices.prices.find(price => 
      startOfHour(new Date(price.hour)).toISOString() === nextHourTime.toISOString()
    );

    return {
      currentPrice: currentPriceData,
      nextPrice: nextPriceData
    };
  }, [latestPrices, currentHour]);

  const averagePrice = useMemo(() => {
    if (!Array.isArray(memoizedDailyPrices) || memoizedDailyPrices.length === 0) return 0;
    return calculateAverage(memoizedDailyPrices.map(p => p.price));
  }, [memoizedDailyPrices]);

  const handleTimeRangeChange = useCallback((timeRange: TimeRange, startDate?: Date, endDate?: Date) => {
    console.log("Time range changed to:", timeRange, { startDate, endDate });
    setSelectedTimeRange(timeRange);
    if (timeRange === 'custom') {
      setCustomStartDate(startDate);
      setCustomEndDate(endDate);
    } else {
      setCustomStartDate(undefined);
      setCustomEndDate(undefined);
    }
  }, []);

  const handleDayChange = useCallback((day: 'today' | 'tomorrow') => {
    setSelectedDay(day);
  }, []);

  const tomorrowPricesAvailable = useMemo(() => {
    if (!Array.isArray(dailyPrices)) return false;
    const tomorrow = addDays(new Date(), 1);
    return dailyPrices.some(price => !isBefore(new Date(price.hour), tomorrow));
  }, [dailyPrices]);

  return (
    <>
      <Header />
      <main className="bg-gradient-to-b from-gray-50 to-white min-h-screen">
        <div className="container py-8">
          <h1 className="text-4xl font-bold mb-8 text-slate-800">Pörssisähkön hinta</h1>
          
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mb-8">
            <CurrentPrice currentPrice={currentPrice} nextPrice={nextPrice} />
            
            <Card className="shadow-lg border-0 card-gradient">
              <CardHeader className="border-b border-blue-100/50">
                <CardTitle className="text-blue-900">Päivän keskihinta</CardTitle>
              </CardHeader>
              <CardContent className="pt-6">
                <div className="text-4xl font-bold text-slate-800">
                  {averagePrice.toFixed(2).replace('.', ',')}
                  <span className="text-2xl ml-2 text-slate-600">snt/kWh</span>
                </div>
              </CardContent>
            </Card>
          </div>

          {Array.isArray(memoizedDailyPrices) && (
            <>
              <Card className="mb-8 shadow-lg border-0 card-gradient overflow-hidden">
                <CardHeader className="border-b border-blue-100/50">
                  <CardTitle className="text-blue-900">Päivän tuntikohtaiset spot-hinnat</CardTitle>
                </CardHeader>
                <CardContent className="pt-6">
                  <div className="price-chart-container">
                    <PriceChart 
                      data={memoizedDailyPrices} 
                      aggregationType="hourly"
                      isLoading={isLoading}
                      onDayChange={handleDayChange}
                      selectedDay={selectedDay}
                      tomorrowPricesAvailable={tomorrowPricesAvailable}
                    />
                  </div>
                  <div className="mt-8">
                    <PriceTable prices={memoizedDailyPrices} />
                  </div>
                </CardContent>
              </Card>

              <HistoricalPrices 
                prices={historicalPrices} 
                onTimeRangeChange={handleTimeRangeChange}
                isLoading={isLoading}
              />
            </>
          )}
          
          <Content />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Index;
