import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { DailyPrice } from "@/types/price";
import { format } from "date-fns";
import { fi } from "date-fns/locale";

interface PriceTableProps {
  prices: DailyPrice[];
}

const PriceTable = ({ prices }: PriceTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Aika</TableHead>
          <TableHead>Hinta (snt/kWh)</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {prices.map((price) => (
          <TableRow key={price.hour}>
            <TableCell>{format(new Date(price.hour), 'HH:00', { locale: fi })}</TableCell>
            <TableCell>{price.price.toFixed(2).replace('.', ',')}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PriceTable;