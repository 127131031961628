import { format } from 'date-fns';
import { fi } from 'date-fns/locale';

export const roundToNext5 = (value: number) => Math.ceil(value / 5) * 5;

export const getChartMargins = () => ({
  left: 10,     // Positive margin to show values
  right: 10,    // Keep small right margin
  bottom: 30,   // Significantly reduced bottom margin
  top: 10 
});

export const formatAggregatedData = (data: any[], aggregationType: string) => {
  if (aggregationType === 'hourly') {
    return data.map(price => ({
      time: format(new Date(price.hour), 'HH:00', { locale: fi }),
      price: +price.price.toFixed(2),
      hour: new Date(price.hour),
    }));
  }

  if (aggregationType === 'daily') {
    const dailyGroups = data.reduce((acc, price) => {
      const day = new Date(price.hour).setHours(0, 0, 0, 0);
      if (!acc[day]) {
        acc[day] = { prices: [], day: new Date(day) };
      }
      acc[day].prices.push(price.price);
      return acc;
    }, {} as Record<number, { prices: number[], day: Date }>);

    return Object.values(dailyGroups).map(({ prices, day }) => ({
      time: format(day, 'dd.MM.', { locale: fi }), // Keep year removed from daily format
      price: +(prices.reduce((sum, price) => sum + price, 0) / prices.length).toFixed(2),
    }));
  }

  if (aggregationType === 'monthly') {
    const monthlyGroups = data.reduce((acc, price) => {
      const date = new Date(price.hour);
      const monthKey = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
      if (!acc[monthKey]) {
        acc[monthKey] = { prices: [], month: new Date(monthKey) };
      }
      acc[monthKey].prices.push(price.price);
      return acc;
    }, {} as Record<number, { prices: number[], month: Date }>);

    return Object.values(monthlyGroups).map(({ prices, month }) => ({
      time: format(month, 'MM.yyyy', { locale: fi }),
      price: +(prices.reduce((sum, price) => sum + price, 0) / prices.length).toFixed(2),
    }));
  }

  if (aggregationType === 'yearly') {
    const yearlyGroups = data.reduce((acc, price) => {
      const year = new Date(price.hour).getFullYear();
      if (!acc[year]) {
        acc[year] = { prices: [], year: new Date(year, 0, 1) };
      }
      acc[year].prices.push(price.price);
      return acc;
    }, {} as Record<number, { prices: number[], year: Date }>);

    return Object.values(yearlyGroups).map(({ prices, year }) => ({
      time: format(year, 'yyyy', { locale: fi }),
      price: +(prices.reduce((sum, price) => sum + price, 0) / prices.length).toFixed(2),
    }));
  }

  return [];
};