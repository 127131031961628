// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://inyesrzvrtpqfzypjcad.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlueWVzcnp2cnRwcWZ6eXBqY2FkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzQ5OTMzNDcsImV4cCI6MjA1MDU2OTM0N30.iYDTQY9hGzE9DhiLCXaSdQGTa6wlRHy0ac4ltjHIgzI";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);