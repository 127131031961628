import { Card, CardContent } from "@/components/ui/card";
import { DailyPrice } from "@/types/price";
import { calculateAverage } from "@/services/priceService";

interface AveragePriceCardProps {
  prices: DailyPrice[];
}

export const AveragePriceCard = ({ prices }: AveragePriceCardProps) => {
  const averagePrice = Array.isArray(prices) && prices.length > 0
    ? calculateAverage(prices.map(price => price.price))
    : 0;

  return (
    <Card>
      <CardContent className="pt-6">
        <div className="text-2xl font-bold">
          {averagePrice.toFixed(2).replace('.', ',')}
          <span className="text-xl ml-2">snt/kWh</span>
        </div>
        <p className="text-sm text-muted-foreground">Keskihinta valitulla ajanjaksolla</p>
      </CardContent>
    </Card>
  );
};