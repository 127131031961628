import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import { ReferenceLine } from 'recharts';
import { startOfHour } from 'date-fns';

interface CurrentTimeMarkerProps {
  show: boolean;
}

export const CurrentTimeMarker = ({ show }: CurrentTimeMarkerProps) => {
  if (!show) return null;
  
  const currentTime = startOfHour(new Date());
  
  return (
    <ReferenceLine
      x={format(currentTime, 'dd.MM. HH:00', { locale: fi })}
      stroke="hsl(var(--muted-foreground))"
      strokeWidth={2}
      strokeOpacity={0.8}
      label={{ 
        value: 'Nykyhetki',
        position: 'top',
        fill: 'hsl(var(--muted-foreground))',
        fontSize: 12
      }}
      className="bg-muted/20"
    />
  );
};