
import React, { useEffect, useState } from 'react';

const Content = () => {
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    // Get the seo-content element from the HTML
    const seoContentElement = document.getElementById('seo-content');
    
    if (seoContentElement) {
      // Move the seo-content element into our React component
      const contentContainer = document.getElementById('content-container');
      if (contentContainer) {
        // Remove any existing children from the content container
        while (contentContainer.firstChild) {
          contentContainer.removeChild(contentContainer.firstChild);
        }
        
        // Clone the seo-content to avoid removing it from its original location
        const contentClone = seoContentElement.cloneNode(true) as HTMLElement;
        contentContainer.appendChild(contentClone);
        
        // Apply proper styling to the content
        contentClone.classList.add('prose', 'prose-slate', 'max-w-4xl', 'mx-auto', 
          'bg-gradient-to-b', 'from-white', 'to-blue-50/30', 'rounded-xl', 
          'px-6', 'shadow-sm', 'py-16');
        
        // Completely remove the original seo-content from DOM instead of just hiding it
        seoContentElement.remove();
        
        // Mark as loaded
        setContentLoaded(true);
      }
    }
  }, []);

  return (
    <section className="py-8 mb-8">
      <div id="content-container" className={`transition-opacity ${contentLoaded ? 'opacity-100' : 'opacity-0'}`}>
        {/* The SEO content will be dynamically inserted here */}
      </div>
    </section>
  );
};

export default Content;
