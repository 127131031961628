import { useQuery } from '@tanstack/react-query';
import { fetchLatestPrices, fetchDailyPrices, fetchHistoricalPrices } from '@/services/priceService';
import { useToast } from '@/hooks/use-toast';
import { TimeRange } from '@/types/price';
import { addDays, subDays, startOfDay, endOfDay, subYears } from 'date-fns';

export const usePriceData = (timeRange?: TimeRange, selectedDay: 'today' | 'tomorrow' = 'today', customStartDate?: Date, customEndDate?: Date) => {
  const { toast } = useToast();

  const { data: latestPrices, isLoading: isLoadingLatest } = useQuery({
    queryKey: ['latestPrices'],
    queryFn: fetchLatestPrices,
    meta: {
      onError: () => {
        toast({
          title: "Virhe hintatietojen haussa",
          description: "Hintatietojen haku epäonnistui",
          variant: "destructive",
        });
      },
    },
  });

  const queryDate = selectedDay === 'today' ? new Date() : addDays(new Date(), 1);

  const { data: dailyPrices, isLoading: isLoadingDaily } = useQuery({
    queryKey: ['dailyPrices', queryDate.toISOString().split('T')[0], selectedDay],
    queryFn: () => fetchDailyPrices(queryDate),
    meta: {
      onError: () => {
        toast({
          title: "Virhe päivän hintojen haussa",
          description: "Päivän hintojen haku epäonnistui",
          variant: "destructive",
        });
      },
    },
  });

  // Adjust the date range for historical prices to exclude today
  const getHistoricalDateRange = (range: TimeRange) => {
    const today = startOfDay(new Date());
    const yesterday = subDays(today, 1);
    
    switch (range) {
      case '7d':
        return { 
          start: startOfDay(subDays(yesterday, 6)), // 7 days ago
          end: endOfDay(yesterday) // Until end of yesterday
        };
      case '30d':
        return { start: subDays(yesterday, 29), end: yesterday };
      case '1y':
        return { 
          start: startOfDay(subYears(yesterday, 1)), 
          end: endOfDay(yesterday) 
        };
      case '5y':
        return { 
          start: startOfDay(subYears(yesterday, 5)), 
          end: endOfDay(yesterday) 
        };
      case 'custom':
        if (customStartDate && customEndDate) {
          return { 
            start: startOfDay(customStartDate), 
            end: endOfDay(customEndDate)
          };
        }
        return undefined;
      default:
        return undefined;
    }
  };

  const { data: historicalPrices, isLoading: isLoadingHistorical } = useQuery({
    queryKey: ['historicalPrices', timeRange, customStartDate?.toISOString(), customEndDate?.toISOString()],
    queryFn: () => {
      console.log('Fetching historical prices:', { timeRange, customStartDate, customEndDate });
      const dateRange = getHistoricalDateRange(timeRange || '7d');
      if (!dateRange) {
        console.warn('No date range available for historical prices');
        return [];
      }
      console.log('Using date range:', dateRange);
      return fetchHistoricalPrices(timeRange || '7d', dateRange.start, dateRange.end);
    },
    enabled: !!timeRange && (timeRange !== 'custom' || (!!customStartDate && !!customEndDate)),
    meta: {
      onError: () => {
        toast({
          title: "Virhe historiallisten hintojen haussa",
          description: "Historiallisten hintojen haku epäonnistui",
          variant: "destructive",
        });
      },
    },
  });

  return {
    latestPrices,
    dailyPrices: dailyPrices || [],
    historicalPrices: historicalPrices || [],
    isLoading: isLoadingLatest || isLoadingDaily || isLoadingHistorical,
  };
};