import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

interface AggregationSelectorProps {
  aggregation: string;
  onAggregationChange: (aggregation: string) => void;
  isHourlyDisabled?: boolean;
  isDailyDisabled?: boolean;
}

export const AggregationSelector = ({
  aggregation,
  onAggregationChange,
  isHourlyDisabled,
  isDailyDisabled,
}: AggregationSelectorProps) => {
  return (
    <div className="relative max-w-full">
      <Carousel className="w-full max-w-xs md:max-w-none mx-auto">
        <CarouselContent className="-ml-1">
          <CarouselItem className="pl-1 basis-auto">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span>
                    <Button
                      variant={aggregation === "hourly" ? "default" : "outline"}
                      onClick={() => onAggregationChange("hourly")}
                      disabled={isHourlyDisabled}
                      className="whitespace-nowrap"
                    >
                      Tuntikeskiarvot
                    </Button>
                  </span>
                </TooltipTrigger>
                {isHourlyDisabled && (
                  <TooltipContent>
                    <p>Tuntikohtaiset hinnat on nähtävillä korkeintaan 7 päivän pituiselle ajanjaksolle</p>
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
          </CarouselItem>

          <CarouselItem className="pl-1 basis-auto">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span>
                    <Button
                      variant={aggregation === "daily" ? "default" : "outline"}
                      onClick={() => onAggregationChange("daily")}
                      disabled={isDailyDisabled}
                      className="whitespace-nowrap"
                    >
                      Päiväkeskiarvot
                    </Button>
                  </span>
                </TooltipTrigger>
                {isDailyDisabled && (
                  <TooltipContent>
                    <p>Päiväkeskiarvot eivät ole saatavilla yli vuoden jaksoille</p>
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
          </CarouselItem>

          <CarouselItem className="pl-1 basis-auto">
            <Button
              variant={aggregation === "monthly" ? "default" : "outline"}
              onClick={() => onAggregationChange("monthly")}
              className="whitespace-nowrap"
            >
              Kuukausikeskiarvot
            </Button>
          </CarouselItem>

          <CarouselItem className="pl-1 basis-auto">
            <Button
              variant={aggregation === "yearly" ? "default" : "outline"}
              onClick={() => onAggregationChange("yearly")}
              className="whitespace-nowrap"
            >
              Vuosikeskiarvot
            </Button>
          </CarouselItem>
        </CarouselContent>
        <CarouselPrevious className="left-0" />
        <CarouselNext className="right-0" />
      </Carousel>
    </div>
  );
};