import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { TimeRange } from "@/types/price";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { format } from "date-fns";
import { fi } from "date-fns/locale";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

interface TimeRangeSelectorProps {
  timeRange: TimeRange;
  onTimeRangeChange: (timeRange: TimeRange) => void;
  startDate?: Date;
  endDate?: Date;
  onDateChange: (start?: Date, end?: Date) => void;
}

export const TimeRangeSelector = ({
  timeRange,
  onTimeRangeChange,
  startDate,
  endDate,
  onDateChange,
}: TimeRangeSelectorProps) => {
  const [startOpen, setStartOpen] = useState(false);
  const [endOpen, setEndOpen] = useState(false);

  const handleStartSelect = (date: Date | undefined) => {
    onDateChange(date, endDate);
    setStartOpen(false);
    if (timeRange !== 'custom') {
      onTimeRangeChange('custom');
    }
  };

  const handleEndSelect = (date: Date | undefined) => {
    onDateChange(startDate, date);
    setEndOpen(false);
    if (timeRange !== 'custom') {
      onTimeRangeChange('custom');
    }
  };

  const handleTimeRangeClick = (newTimeRange: TimeRange) => {
    if (newTimeRange !== 'custom') {
      onDateChange(undefined, undefined);
    }
    onTimeRangeChange(newTimeRange);
  };

  useEffect(() => {
    if (startDate && endDate && timeRange === 'custom') {
      console.log('Custom date range selected:', { startDate, endDate });
      onDateChange(startDate, endDate);
    }
  }, [startDate, endDate, timeRange, onDateChange]);

  return (
    <div className="space-y-4">
      <div className="relative max-w-full">
        <Carousel className="w-full max-w-xs md:max-w-none mx-auto">
          <CarouselContent className="-ml-1">
            <CarouselItem className="pl-1 basis-auto">
              <Button
                variant={timeRange === "7d" ? "default" : "outline"}
                onClick={() => handleTimeRangeClick("7d")}
                className="whitespace-nowrap"
              >
                Viimeiset 7 päivää
              </Button>
            </CarouselItem>
            <CarouselItem className="pl-1 basis-auto">
              <Button
                variant={timeRange === "30d" ? "default" : "outline"}
                onClick={() => handleTimeRangeClick("30d")}
                className="whitespace-nowrap"
              >
                Viimeiset 30 päivää
              </Button>
            </CarouselItem>
            <CarouselItem className="pl-1 basis-auto">
              <Button
                variant={timeRange === "1y" ? "default" : "outline"}
                onClick={() => handleTimeRangeClick("1y")}
                className="whitespace-nowrap"
              >
                Viimeinen vuosi
              </Button>
            </CarouselItem>
            <CarouselItem className="pl-1 basis-auto">
              <Button
                variant={timeRange === "5y" ? "default" : "outline"}
                onClick={() => handleTimeRangeClick("5y")}
                className="whitespace-nowrap"
              >
                Viimeiset 5 vuotta
              </Button>
            </CarouselItem>
            <CarouselItem className="pl-1 basis-auto">
              <Button
                variant={timeRange === "custom" ? "default" : "outline"}
                onClick={() => handleTimeRangeClick("custom")}
                className="whitespace-nowrap"
              >
                Mukautettu aikaväli
              </Button>
            </CarouselItem>
          </CarouselContent>
          <CarouselPrevious className="left-0" />
          <CarouselNext className="right-0" />
        </Carousel>
      </div>

      {timeRange === "custom" && (
        <div className="flex gap-4 flex-wrap">
          <Popover open={startOpen} onOpenChange={setStartOpen}>
            <PopoverTrigger asChild>
              <Button variant="outline">
                {startDate ? format(startDate, 'dd.MM.yyyy', { locale: fi }) : 'Valitse alkupäivä'}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={startDate}
                onSelect={handleStartSelect}
                initialFocus
              />
            </PopoverContent>
          </Popover>
          <Popover open={endOpen} onOpenChange={setEndOpen}>
            <PopoverTrigger asChild>
              <Button variant="outline">
                {endDate ? format(endDate, 'dd.MM.yyyy', { locale: fi }) : 'Valitse loppupäivä'}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={endDate}
                onSelect={handleEndSelect}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>
      )}
    </div>
  );
};