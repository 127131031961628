import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';

interface TomorrowMessageProps {
  onRefresh: () => void;
}

export const TomorrowMessage = ({ onRefresh }: TomorrowMessageProps) => (
  <Card>
    <CardContent className="pt-6">
      <p className="text-center text-muted-foreground">
        Huomisen hinnat julkaistaan n. klo 14.
      </p>
      <div className="flex justify-center mt-4">
        <Button onClick={onRefresh}>
          Päivitä
        </Button>
      </div>
    </CardContent>
  </Card>
);