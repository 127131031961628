
const Footer = () => {
  return (
    <footer className="bg-blue-800 text-white py-10 mt-16">
      <div className="container mx-auto">
        <div className="text-center">
          <p>© {new Date().getFullYear()} Pörssisähkö.fi</p>
          <p className="mt-2">
            <a href="mailto:info@porssisahko.fi" className="text-blue-200 hover:text-blue-100 transition-colors">
              info@porssisahko.fi
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
