import { supabase } from "@/integrations/supabase/client";
import { DailyPrice, TimeRange } from "@/types/price";
import { startOfDay, differenceInDays, differenceInMonths, subHours, addDays, subYears } from "date-fns";

export const fetchLatestPrices = async () => {
  const now = new Date();
  const twoHoursAgo = subHours(now, 2);
  console.log('Fetching latest prices from:', twoHoursAgo.toISOString());
  
  const { data, error } = await supabase
    .from('electricity_prices')
    .select('*')
    .gte('hour', twoHoursAgo.toISOString())
    .order('hour', { ascending: true })
    .limit(24);

  if (error) {
    console.error('Error fetching latest prices:', error);
    throw error;
  }

  return {
    prices: data.map(price => ({
      hour: price.hour,
      price: price.price
    }))
  };
};

export const fetchDailyPrices = async (date: Date): Promise<DailyPrice[]> => {
  console.log('Fetching daily prices for date:', date);
  const startOfDayDate = startOfDay(date);
  const endOfDayDate = startOfDay(addDays(date, 1));

  console.log('Query range:', {
    start: startOfDayDate.toISOString(),
    end: endOfDayDate.toISOString()
  });

  const { data, error } = await supabase
    .from('electricity_prices')
    .select('*')
    .gte('hour', startOfDayDate.toISOString())
    .lt('hour', endOfDayDate.toISOString())
    .order('hour', { ascending: true });

  if (error) {
    console.error('Error fetching daily prices:', error);
    throw error;
  }

  if (!data) {
    console.log('No daily prices found');
    return [];
  }

  console.log('Found prices:', data.length);
  return data.map(price => ({
    hour: price.hour,
    price: price.price
  }));
};

export const fetchHistoricalPrices = async (timeRange: TimeRange, startDate?: Date, endDate?: Date): Promise<DailyPrice[]> => {
  console.log('Fetching historical prices for timeRange:', timeRange);
  const now = new Date();
  let queryStartDate = startDate;
  let queryEndDate = endDate || now;

  if (!startDate) {
    switch (timeRange) {
      case "7d":
        queryStartDate = addDays(now, -7); // Get last 7 full days
        queryEndDate = addDays(now, -1); // Up to yesterday
        break;
      case "30d":
        queryStartDate = addDays(now, -30);
        queryEndDate = addDays(now, -1);
        break;
      case "90d":
        queryStartDate = addDays(now, -90);
        break;
      case "1y":
        queryStartDate = subYears(now, 1);
        break;
      case "5y":
        queryStartDate = subYears(now, 5);
        break;
      case "custom":
        // For custom range without dates, return empty array
        if (!startDate || !endDate) return [];
        queryStartDate = startDate;
        queryEndDate = endDate;
        break;
    }
  }

  if (!queryStartDate) return [];

  const daysInRange = differenceInDays(queryEndDate, queryStartDate);
  const monthsInRange = differenceInMonths(queryEndDate, queryStartDate);

  console.log('Historical prices query parameters:', {
    timeRange,
    startDate: queryStartDate.toISOString(),
    endDate: queryEndDate.toISOString(),
    daysInRange,
    monthsInRange
  });

  try {
    // For periods longer than a year, fetch from monthly averages
    if (monthsInRange >= 12) {
      const { data, error } = await supabase
        .from('electricity_monthly_averages')
        .select('*')
        .gte('year_month', queryStartDate.toISOString().split('T')[0])
        .lte('year_month', queryEndDate.toISOString().split('T')[0])
        .order('year_month', { ascending: true });

      if (error) throw error;

      return data.map(record => ({
        hour: record.year_month,
        price: record.average_price
      }));
    }

    // For periods longer than 30 days but less than a year, fetch from daily averages
    if (daysInRange > 30) {
      const { data, error } = await supabase
        .from('electricity_daily_averages')
        .select('*')
        .gte('date', queryStartDate.toISOString().split('T')[0])
        .lte('date', queryEndDate.toISOString().split('T')[0])
        .order('date', { ascending: true });

      if (error) throw error;

      return data.map(record => ({
        hour: record.date,
        price: record.average_price
      }));
    }

    // For periods up to 30 days, fetch hourly data
    const { data, error } = await supabase
      .from('electricity_prices')
      .select('*')
      .gte('hour', queryStartDate.toISOString())
      .lte('hour', queryEndDate.toISOString())
      .order('hour', { ascending: true });

    if (error) {
      console.error('Error fetching historical prices:', error);
      throw error;
    }

    if (!data) {
      console.log('No historical prices found');
      return [];
    }

    return data.map(price => ({
      hour: price.hour,
      price: price.price
    }));
  } catch (error) {
    console.error('Error fetching historical prices:', error);
    throw error;
  }
};