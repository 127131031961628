import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://inyesrzvrtpqfzypjcad.supabase.co";
const SUPABASE_ANON_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlueWVzcnp2cnRwcWZ6eXBqY2FkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDk2NTQxNzAsImV4cCI6MjAyNTIzMDE3MH0.SvQ1PuVEGhN8kFU66z_h_pQYEV-VvXxTSCrbHgE_7AE";

export const supabaseAdmin = createClient<Database>(
  SUPABASE_URL,
  SUPABASE_ANON_KEY,
  {
    auth: {
      autoRefreshToken: false,
      persistSession: false
    }
  }
);