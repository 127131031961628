import { Button } from '@/components/ui/button';

interface DaySelectorProps {
  selectedDay: 'today' | 'tomorrow';
  onDayChange: (day: 'today' | 'tomorrow') => void;
}

export const DaySelector = ({ selectedDay, onDayChange }: DaySelectorProps) => (
  <div className="flex gap-4 items-center">
    <Button 
      variant={selectedDay === 'today' ? 'default' : 'outline'}
      onClick={() => onDayChange('today')}
    >
      Tänään
    </Button>
    <Button 
      variant={selectedDay === 'tomorrow' ? 'default' : 'outline'}
      onClick={() => onDayChange('tomorrow')}
    >
      Huomenna
    </Button>
  </div>
);