
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="bg-gradient-to-r from-blue-700 to-blue-600 shadow-md">
      <div className="container mx-auto py-4">
        <nav className="flex items-center justify-between">
          <Link to="/" className="text-xl font-bold text-white hover:text-blue-100 transition-colors">
            Pörssisähkö.fi
          </Link>
          <div className="flex gap-4">
            <Link to="/" className="text-white hover:text-blue-100 transition-colors">Etusivu</Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
